export default {
  inova: {
    primary: '#B9E600',
    contrast: 'dimgray',
  },
  tosalvo: {
    primary: '#17a2b8',
    contrast: 'white',
  },
}
